@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Open+Sans&family=Lato&family=Montserrat&family=Poppins&family=Raleway&family=Oswald&family=Merriweather&family=Playfair+Display&family=Inter&display=swap');

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

:root {
  --vh: 1vh;
}

html {
  /* min-height: 100vh; */
  padding-right: 0 !important;
}

img {
  height: auto;
  max-width: none;
  vertical-align: unset;
}

#replace_me_v2_recorder .ba-videorecorder-container,
#replace_me_v2_player .ba-videoplayer-container {
  display: block;
}

@media (max-width: 1023px) {
  .ba-videorecorder-leftbutton-container:nth-child(2) {
    display: none;
  }
}

.Toastify__toast-body {
  @apply ag-font-medium ag-text-sm ag-text-gray-900;
}
